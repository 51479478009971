@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
[data-sparta-container] .#{$moduleNameSpace} .navigation {

  $smallish-only: "(min-width: 40.063em) and (max-width: 47.938em)";
  $smallish-down: "(max-width: 47.938em)";
  $smallish-up: "(min-width: 40.063em)";
  $smallish-breakpoint: rem-calc(767);

  $maxHeight: 3000px;

  $transition-duration-max-height: 300ms;
  $transition-duration-height: 200ms;


  $navigation-title--color: $copy-gray-1; 
  $navigation-title--hover-color: #001129;
  $navigation-title--bg-hover-color: #f2ede7;

  $navigation-title-arrow--transition-duration: 0.3s;


  $navigation-item--color: $brand-royal-blue;
  $navigation-item--hover-color: #001129;
  $navigation-item--bg-hover-color: #f2ede7;

  background-color: $sb-pale-gray-light; 


  & {
    overflow: hidden;
    max-height: 0;
    transition: max-height $transition-duration-max-height cubic-bezier(0, 1, 0, 1);
  }

  &.expanded {
    transition: max-height $transition-duration-max-height ease-in-out;
    max-height: 3000px;
  }

  .menu-items-container {
    padding-bottom: 15px;
  }


  &__sub-container {
    padding: 14px;
    border-bottom: 1px solid $sb-warm-gray-light;
  }

  &__sub {
    list-style: none;
    margin: 0;
    float: left;

    @media #{$small-only} {
      float: none;
    }

    @media #{$medium-up} {
      float: right;
      margin-right: 25px;
    }

    li {
      text-decoration: none;
      float: left;
      width: 50%;

      @media #{$smallish-up} {
        width: auto;
      }

      &.hide-for-smallish-and-large-up {
        @media #{$smallish-only} {
          display: none;
        }

        @media #{$large-up} {
          display: none;
        }
      }

      a {
        font-size: 12px;
        display: flex;
        text-decoration: none;
        padding: 10px;

        @media #{$smallish-up} {
          display: inline-block;
          padding: 8px 10px 8px 10px;
        }

        @media #{$large-up} {
          font-size: 13px;
        }

        &::before {
          text-align: center;
          height: 16px;
          width: 16px;
          margin-right: 10px;
          display: inline-block;

          @media #{$smallish-up} {
            position: relative;
            margin-right: 5px;
            top: 2px;
          }
        }
        &:hover, &:focus {
          color: $navigation-item--hover-color;
          background-color: $navigation-item--bg-hover-color;
        }
      }
    }
  }


  &__label {
    padding: 10px 0 10px 6px;
    font-size: 16px;
    color: $brand-regal-red; 

    @media #{$small-only} {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media #{$large-up} {
      padding: 15px 0 0 6px;
      font-size: 18px;
      line-height: 25px;
    }
  }

  &__title {
    display: block;
    padding: 15px 10px 10px 6px;
    margin: 0;

    color: $navigation-title--color;
    font-size: 13px;
    font-weight: 700;
    text-decoration: none;

    @media #{$small-only} {
      width: 100%;
      padding-left: 10px;
      border-top: 2px solid $white;

      &:hover {
        color: $navigation-title--hover-color;
        background-color: $navigation-title--bg-hover-color;
      }
    }

    @media #{$smallish-up} {
      display: inline-block;
    }

    @media #{$large-up} {
      padding: 26px 25px 12px 6px;
    }
  }

  &-title-arrow {
    transition: transform $navigation-title-arrow--transition-duration ease-out;
  }

  &__list {
    width: 100%;
    margin: 0;
    list-style: none;
    font-size: 1rem;
    line-height: 1.6;

    @media #{$small-only} {
      overflow: hidden;
      transition: height $transition-duration-height ease-out;

      &.collapsed {
        height: 0 !important; 
      }
    }
  }

  &__item {
    margin: 0;
    padding: 0;

        a {
      display: block;
      padding: 5px 6px;
      color: $navigation-item--color;
      font-size: 14px;
      font-weight: 300;
      text-decoration: none;

      @media #{$small-only} {
        padding-left: 10px;
      }

      &:hover, &:focus {
        color: $navigation-item--hover-color;
        background-color: $navigation-item--bg-hover-color;
      }
    }
  }


  &__title--open {
    &-title-arrow {
      transform: rotate(180deg);
    }
  }

  .navigation__column + .navigation__column:last-child {
    float: left;
  }
}
